import React from "react"
import Layout from "../components/Layout"
import { Link } from "gatsby"
import { Location } from "@reach/router"

const Error = () => {
  return (
    <Location>
      {({ location }) => {
        const pathSegments = location.pathname.split("/").filter(Boolean)
        const userName = pathSegments[pathSegments.length - 1]
        const realUserName = userName.replace(/\./g, " ")
        return (
          <Layout>
            <main className="error-page">
              <div className="error-container">
                <h1>{realUserName}</h1>
                <Link to="/" className="btn">
                  Vrati me nazad!
                </Link>
              </div>
            </main>
          </Layout>
        )
      }}
    </Location>
  )
}

export default Error
